<template>
  <div class="container h-100">
    <div class="row align-items-center justify-content-center h-100">
      <div class="col-5 m-auto">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <h5 class="m-0">Logowanie</h5>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent.stop="handleSubmit">
              <div class="mb-3">
                <label for="username">Login</label>
                <input
                  id="username"
                  v-model="form.username"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="password">Hasło</label>
                <input
                  id="password"
                  v-model="form.password"
                  type="password"
                  class="form-control"
                  required
                />
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-primary">
                  Zaloguj się
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { LOGIN } from "@/core/services/store/auth.module";

export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"])
  },
  methods: {
    handleSubmit() {
      const { username, password } = this.form;
      setTimeout(() => {
        this.$store
          .dispatch(LOGIN, { username, password })
          .then(() => this.$router.push({ name: "dashboard" }))
          .catch(() => {});
      }, 2000);
    }
  }
};
</script>
